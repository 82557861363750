const api = {
  baseQuery: () => '/account/loyalty-program',
}

export default ($request) => ({
  async getLoyaltyProgram() {
    return await $request.$get(api.baseQuery())
  },
  async getLoyaltyHistory(payload) {
    return await $request.$get('/account/loyalty-program-history', payload)
  },
})
