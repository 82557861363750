import Cookies from 'js-cookie'
import { CLEAR_USER } from './../user/types.user'
import {
  HAS_FINGERPRINT_KEY,
  IS_FINGERPRINT_KEY,
  HAS_TOKEN,
  IS_REFRESH_TOKEN,
  SET_FINGERPRINT_KEY,
  SET_TOKEN,
  GET_FINGERPRINT_KEY,
  REFRESH_TOKEN,
  CLEAR_TOKEN,
  LOGOUT,
} from './types.auth'

export const state = () => ({
  fingerprintKey: null,
  token: null,
  refreshToken: null,
})

export const getters = {
  [HAS_FINGERPRINT_KEY]: (state) => Boolean(state.fingerprintKey),
  [IS_FINGERPRINT_KEY]: (state) => state.fingerprintKey,
  [HAS_TOKEN]: (state) => Boolean(state.token),
  [IS_REFRESH_TOKEN]: (state) => state.refreshToken,
}

export const mutations = {
  [SET_FINGERPRINT_KEY]: (state, payload) => (state.fingerprintKey = payload),
  [SET_TOKEN]: (state, { token }) => {
    state.token = token
    // state.refreshToken = refreshToken
  },
  [CLEAR_TOKEN]: (state) => {
    state.token = null
    state.refreshToken = null
  },
}

export const actions = {
  [GET_FINGERPRINT_KEY]({ commit }) {
    return this.$fingerprint
      .load()
      .then((fp) => fp.get())
      .then(({ visitorId }) => {
        localStorage.fingerprintKey = visitorId
        commit(SET_FINGERPRINT_KEY, visitorId)
      })
  },
  [REFRESH_TOKEN]({ getters, commit }) {
    const postData = {
      grant_type: 'refresh_token',
      refresh_token: getters[IS_REFRESH_TOKEN],
    }
    return this.$request.$post('/oauth/token', postData).then(({ payload }) => {
      commit(SET_TOKEN, {
        token: payload.access_token,
        refreshToken: payload.refresh_token,
      })
      return payload
    })
  },

  [LOGOUT]({ commit }) {
    commit(CLEAR_TOKEN)
    commit(`user/${CLEAR_USER}`, null, { root: true })
    Cookies.remove('vuex')
    Cookies.remove('refreshVuex')
    this.$router.push(this.localePath({ name: '/' }))
  },
}
