import pic1 from '../assets/images/default/product-1.png'
import pic2 from '../assets/images/default/product-2.png'
export const favorites = [
  {
    picture: pic1,
    name: 'Harlow Beige, Double bed ',
    slug: 'favorite-product-1',
  },
  {
    picture: pic2,
    name: 'Harlow Velvet, Double bed ',
    slug: 'favorite-product-2',
  },
  {
    picture: pic1,
    name: 'Harlow Velvet, Double bed ',
    slug: 'favorite-product-3',
  },
  {
    picture: pic2,
    name: 'Harlow Velvet, Double bed ',
    slug: 'favorite-product-4',
  },
  {
    picture: pic1,
    name: 'Harlow Velvet, Double bed ',
    slug: 'favorite-product-5',
  },
  {
    picture: pic2,
    name: 'Harlow Velvet, Double bed ',
    slug: 'favorite-product-6',
  },
  {
    picture: pic1,
    name: 'Harlow Velvet, Double bed ',
    slug: 'favorite-product-7',
  },
]
