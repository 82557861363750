import { SET_ERROR, CLEAR_ERROR } from '../types.main'
import { GET_TICKETS, SET_TICKETS, FETCH_TICKETS } from './types.tickets'

export const state = () => ({
  tickets: null,
})

export const getters = {
  [GET_TICKETS]: (state) => state.tickets,
}

export const mutations = {
  [SET_TICKETS]: (state, payload) => (state.tickets = payload),
}

export const actions = {
  [FETCH_TICKETS]({ commit }) {
    commit(CLEAR_ERROR, '', { root: true })
    return this.$api.tickets
      .getTickets()
      .then(({ data }) => commit(SET_TICKETS, data))
      .catch((error) => commit(SET_ERROR, error, { root: true }))
  },
}
