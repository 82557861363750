'use strict'
import qs from 'qs'

function paramsSerializerQs(params) {
  return qs.stringify(params, { arrayFormat: 'brackets' })
}
function prepareParams(params) {
  const obj = {}
  if (typeof params === 'object' && !Array.isArray(params) && params !== null) {
    for (const key in params) {
      if (Object.hasOwnProperty.call(params, key)) {
        if (typeof params[key] === 'string' && params[key].length === 0) {
          continue
        }
        obj[key] = params[key]
      }
    }
    return obj
  } else {
    return params
  }
}

export { paramsSerializerQs, prepareParams }