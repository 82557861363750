import { IS_MOBILE, IS_LAPTOP, IS_LARGE_TABLET, IS_TABLET,IS_DESKTOP } from '~/store/device/type.device'

export default {
  computed: {
    isMobile() {
      return this.$store.getters[`device/${IS_MOBILE}`]
    },
    isLaptop() {
      return this.$store.getters[`device/${IS_LAPTOP}`]
    },
    isLargeTable() {
      return this.$store.getters[`device/${IS_LARGE_TABLET}`]
    },
    isTablet() {
      return this.$store.getters[`device/${IS_TABLET}`]
    },
    isDesktop() {
      return this.$store.getters[`device/${IS_DESKTOP}`]
    },
  },
}
