import { BREADCRUMBS, SET_BC } from './types.bc'
export const state = () => ({
  breadcrumbs: [],
})

export const getters = {
  [BREADCRUMBS]: (state) => state.breadcrumbs,
}

export const mutations = {
  [SET_BC](state, payload) {
    if (!Array.isArray(payload)) {
      throw new TypeError('Breadcrumbs must be an array')
    }
    const breadcrumbs = [
      {
        name: 'Home',
        slug: this.localePath('/'),
      },
      ...payload.map((item) => ({
        name: item.name,
        slug: this.localePath(item.slug),
      })),
    ]
    state.breadcrumbs = breadcrumbs
  },
}
