import { paramsSerializerQs, prepareParams } from '../helpers/api.helpers'

export default function({ $axios, $config, i18n, store, app, redirect }, inject) {
  const makeRequest = () => {
    const lang = i18n.loadedLanguages[0]
    const _request = $axios.create({ baseURL: `${$config.apiURL}/${lang}` })
    // let isRefreshing = false
    // let subscribers = []

    // const subscribeTokenRefresh = cb => subscribers.push(cb)
    // const onRefreshed = token => subscribers.map(cb => cb(token))

    _request.onRequest((config) => {
      const fingerprintKey = store.getters['auth/getFingerprintKey']
      config.headers['Accept-Language'] = app.i18n.locale
      if (store.state.auth.token && !config.url.startsWith('/oauth/')) {
        config.headers.Authorization = `Bearer ${store.state.auth.token}`
        return config
      }
      if (fingerprintKey) {
        config.headers['Client-Fingerprint'] = fingerprintKey
        return config
      }

      return config
    })

    // _request.onResponse((response) => {
    //   if (response && response.data) {
    //     const res = {
    //       ...response,
    //       data: response.data,
    //     }
    //     if (res.data && res.data.error_code) {
    //       switch (res.data.error_code) {
    //       case 401: {
    //         store.commit('setToken', null)
    //         store.commit('setUserInfo', {})
    //         redirect('/authorization')
    //         break
    //       }
    //       }
    //     }
    //     return res
    //   }
    // })

    // _request.onError((error) => {
    //   // const originalRequest = error.config
    //   const code = parseInt(error.response && error.response.status)
    //   // if (code === 401) {
    //   //   if (!isRefreshing && process.client) {
    //   //     isRefreshing = true
    //   //     store
    //   //       .dispatch('auth/refreshToken')
    //   //       .then((payload) => {
    //   //         isRefreshing = false
    //   //         onRefreshed(payload.access_token)
    //   //         subscribers = []
    //   //       })
    //   //       .catch(() => store.dispatch('auth/logout'))
    //   //   }
    //   //   const requestSubscribers = new Promise((resolve) => {
    //   //     subscribeTokenRefresh((token) => {
    //   //       originalRequest.headers.Authorization = `Bearer ${token}`
    //   //       resolve($axios(originalRequest))
    //   //     })
    //   //   })
    //   //   return requestSubscribers
    //   // }
    //   code === 404 && redirect('/404')
    //   code === 403 && redirect('/404')
    //   return Promise.reject(error)
    // })

    const cancelTokens = new Map()
    _request.cancelable = function(key) {
      if (key === undefined) return _request
      if (cancelTokens.has(key)) {
        cancelTokens.get(key).cancel('Operation canceled by race')
        cancelTokens.forEach((value, key) => {
          if (value.token.reason) cancelTokens.delete(key)
        })
      }

      const newRequest = makeRequest()
      const source = $axios.CancelToken.source()
      newRequest.defaults.cancelToken = source.token
      cancelTokens.set(key, source)
      return newRequest
    }

    const { $get, $post, $delete } = _request
    _request.$delete = (url, data) => $delete(url, { ...data })
    _request.$get = (url, params) => {
      const config = {
        params: prepareParams(params),
        paramsSerializer: paramsSerializerQs,
      }
      return $get(url, config)
    }
    _request.getFile = (url) => $get(url, { responseType: 'arraybuffer' })
    _request.postFile = (data, config = {}) => {
      const newConfig = {
        ...config,
        headers: { 'Content-Type': 'multipart/form-data' },
      }
      return $post('/file', data, newConfig)
    }
    return _request
  }

  const request = makeRequest()
  inject('request', request)
}
