const api = {
  baseQuery: () => '/account/favorites',
}

export default ($request) => ({
  async getFavorites(payload) {
    return await $request.$get(api.baseQuery(), payload)
  },
  async addFavorites(payload) {
    return await $request.$post(api.baseQuery(), payload)
  },
  async deleteFavorites(payload) {
    return await $request.$delete(`/account/favorites/${payload}`)
  },
})
