const api = {
  baseQuery: () => '/tickets',
}

export default ($request) => ({
  getTickets(params) {
    return $request.$get(api.baseQuery(), params)
  },
  addTicket(params) {
    return $request.$post(api.baseQuery(), params)
  },
  getTicket(id) {
    return $request.$get(`/tickets/${id}`)
  },
})

