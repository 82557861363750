import Vue from 'vue'

import VueSkeletonLoader from 'skeleton-loader-vue'
Vue.component('VueSkeletonLoader', VueSkeletonLoader)

Vue.mixin({
  methods: {
    ratingToString(num) {
      let result = ''
      if (num === 0) {
        result = 'не сформирован'
      } else if (num > 0 && num < 4) {
        result = 'плохо'
      } else if (num >= 4 && num < 7) {
        result = 'нормально'
      } else if (num >= 7 && num < 9) {
        result = 'хорошо'
      } else if (num >= 9) {
        result = 'превосходно'
      }
      return (result)
    },
    toEllipsis(string) {
      return string && string.length > 31 ? (string.substring(0, 32) + '...') : string
    },
    toEllipsisSmall(string) {
      return string && string.length > 15 ? (string.substring(0, 16) + '...') : string
    },
    goToCommercial(banner) {
      if (banner && banner.url) {
        window.open(banner.url, '_blank')
      }
    },
  },
})
