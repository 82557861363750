const api = {
  baseQuery: (url) => `/main/${url}`,
  sectionsQuery: (url = '') => `/main/sections${url}`,
}
// 947113
export default ($request) => ({
  sectionsList(params) {
    return $request.$get(api.sectionsQuery(), params)
  },
  bannersList(params) {
    return $request.$get(api.baseQuery('banner'), params)
  },
  categoriesList({ id, params }) {
    return $request.$get(api.sectionsQuery(`/${id}/categories`), params)
  },
  productsList({ id, params, type }) {
    return $request.$get(api.sectionsQuery(`/${id}/${type === 1 ? 'products' : 'sets'}`), params)
  },
  roomsList({ id, params }) {
    return $request.$get(api.sectionsQuery(`/${id}/rooms`), params)
  },
  addImage(payload) {
    return $request.$post('/files', payload)
  },
  getStatic(slug) {
    return $request.$get(`/static-content/${slug}`)
  },
})
