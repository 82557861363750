import { SET_ERROR, CLEAR_ERROR } from '../types.main'
import { CLEAR_USER, FETCH_USER, GET_USER, SET_USER } from './types.user'

export const state = () => ({
  user: null,
})

export const getters = {
  [GET_USER]: (state) => state.user,
}

export const mutations = {
  [SET_USER]: (state, payload) => (state.user = payload),
  [CLEAR_USER]: (state) => (state.user = null),
}

export const actions = {
  [FETCH_USER]({ commit }) {
    commit(CLEAR_ERROR, '', { root: true })
    return this.$api.user
      .getUser()
      .then(({ data }) => commit(SET_USER, data))
      .catch((error) => commit(SET_ERROR, error, { root: true }))
  },
}
