
export const state = () => ({
  products_to_refund: [],
})

export const mutations = {
  SET_REFUND_PRODUCTS(state, payload) {
    state.products_to_refund = payload
  },
}

export const actions = {}
