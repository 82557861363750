import createPersistedState from 'vuex-persistedstate'
import Cookies from 'js-cookie'
import cookie from 'cookie'
export default ({ store, req, isDev }) => {
  createPersistedState({
    paths: ['auth.token'],
    storage: {
      getItem: (key) => {
        /* eslint-disable */
        // console.log(process.client)
        // console.log(req)
        return process.client
          ? Cookies.getJSON(key)
          : cookie.parse(req?.headers.cookie || '')[key]
      },
      setItem: (key, value) => {
        // Cookies.set(key, value, { expires: 365, secure: !isDev })
        Cookies.set(key, value, { expires: 365, secure: false })
      },
      removeItem: (key) => Cookies.remove(key),
    },
  })(store)
}
