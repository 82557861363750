// GETTERS
export const HAS_FINGERPRINT_KEY = 'HAS_FINGERPRINT_KEY'
export const IS_FINGERPRINT_KEY = 'IS_FINGERPRINT_KEY'
export const HAS_TOKEN = 'HAS_TOKEN'
export const IS_REFRESH_TOKEN = 'IS_REFRESH_TOKEN'

// MUTATIONS
export const SET_FINGERPRINT_KEY = 'SET_FINGERPRINT_KEY'
export const SET_TOKEN = 'SET_TOKEN'
export const CLEAR_TOKEN = 'CLEAR_TOKEN'

// ACTIONS
export const GET_FINGERPRINT_KEY = 'GET_FINGERPRINT_KEY'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const LOGOUT = 'LOGOUT'
