import {  CLEAR_ERROR, ERROR, SET_ERROR } from './types.main'

export const state = () => ({
  error: false,
  loading: true,
  companyName: '',
})

export const getters = {
  [ERROR]: (state) => state.error,
  getCompanyName(state) {
    return state.companyName
  },

}

export const mutations = {
  [SET_ERROR]: (state) => (state.error = true),
  [CLEAR_ERROR]: (state) => (state.error = false),
  loadingState(state, payload) {
    state.loading = payload
  },
  setCompanyName(state, payload) {
    state.companyName = payload
  },
}

export const actions = {
  async nuxtServerInit({dispatch, getters}) {
    await dispatch('settings/FETCH_SETTINGS')

    if(getters['auth/HAS_TOKEN']) {
      await dispatch('user/FETCH_USER')
      await dispatch('favorites/FETCH_FAVORITES')
      await dispatch('cart/FETCH_CART_PRODUCTS')
      await dispatch('compares/FETCH_COMPARES')
    }
  },
}
