import {
  IS_DESKTOP,
  IS_LAPTOP,
  IS_LARGE_MOBILE,
  IS_LARGE_TABLET,
  IS_MOBILE,
  IS_TABLET,
} from './type.device'
export const state = () => ({
  deviceType: null,
})
export const getters = {
  [IS_MOBILE]: (state) => state.deviceType === 'mobile',
  [IS_LARGE_MOBILE]: (state) => state.deviceType === 'large-mobile',
  [IS_TABLET]: (state) => state.deviceType === 'tablet',
  [IS_LARGE_TABLET]: (state) => state.deviceType === 'large-tablet',
  [IS_LAPTOP]: (state) => state.deviceType === 'laptop',
  [IS_DESKTOP]: (state) => state.deviceType === 'desktop',
}
export const mutations = {
  setDevice(state) {
    const width = window.innerWidth
    if (width > this.$breakpoints.ld) {
      state.deviceType = 'desktop'
    } else if (width <= this.$breakpoints.ld && width > this.$breakpoints.lmd) {
      state.deviceType = 'laptop'
    } else if (width <= this.$breakpoints.lmd && width > this.$breakpoints.md) {
      state.deviceType = 'large-tablet'
    } else if (width <= this.$breakpoints.md && width > this.$breakpoints.sd) {
      state.deviceType = 'tablet'
    } else if (width <= this.$breakpoints.sd) {
      state.deviceType = 'mobile'
    }
  },
}
