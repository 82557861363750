const api = {
  baseQuery: (url) => `/account/${url}`,
  profile: (url = '') => `/account/profile${url}`,
  company(url = '') {
    return `${this.baseQuery('company')}${url}`
  },
}

export default ($request) => ({
  getUser(params) {
    return $request.$get(api.profile(), params)
  },

  updateUser(params) {
    return $request.$patch(api.profile('/contacts'), params)
  },

  updatePassword(params) {
    return $request.$patch(api.profile('/change-password'), params)
  },

  updateSettings(params) {
    return $request.$patch(api.profile('/settings'), params)
  },

  getCompanyInfo(params) {
    return $request.$get(api.company(), params)
  },
  updateCompanyInfo(params) {
    return $request.$put(api.company(), params)
  },

  getCompanyUsers(params) {
    return $request.$get(api.company('/users'), params)
  },

  createCompanyUser(params) {
    return $request.$post(api.company('/users'), params)
  },

  updateCompanyUser(params, id) {
    return $request.$put(api.company(`/users/${id}`), params)
  },

  removeCompanyUser(id) {
    return $request.$delete(api.company(`/users/${id}`))
  },

  getCompanyDeliveryAddresses(params) {
    return $request.$get(api.company('/delivery-addresses'), params)
  },
  createAddress(params) {
    return $request.$post(api.company('/delivery-addresses'), params)
  },
  updateAddress(params, id) {
    return $request.$put(api.company(`/delivery-addresses/${id}`), params)
  },
  removeAddress(id) {
    return $request.$delete(api.company(`/delivery-addresses/${id}`))
  },

  getCode(id) {
    return $request.$get(api.company(`/delivery-addresses/code/${id}`))
  },
  getCodeRegistration(id) {
    return $request.$get(`/address/code/${id}`)
  },
})
