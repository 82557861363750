const api = {
  baseQuery: () => '/account/reviews',
}

export default ($request) => ({
  async getProfileReviews(payload) {
    return await $request.$get(api.baseQuery(), payload)
  },

  async addProductReview(entity, payload, slug) {
    return await $request.$post(`/${entity}/${slug}/reviews`, payload)
  },
  async getProductReviews(payload, slug, typeReview) {
    return await $request.$get(`/${typeReview}/${slug}/reviews`, payload)
  },
})
