import { mapMutations } from 'vuex'

export default {
  methods: {
    ...mapMutations('device', ['setDevice']),
  },
  mounted() {
    this.$nextTick(() => {
      this.setDevice()
    })
    window.addEventListener('resize', this.setDevice)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setDevice)
  },
}
