export const state = () => ({
  items: [],
})

export const getters = {
  getItems(state) {
    return state.items
  },
}

export const mutations = {
  SET_COMPARE(state, product) {
    state.items.push(product)
  },
  SET_COMPARES(state, products) {
    state.items = products
  },
  REMOVE_COMPARE(state, slug) {
    state.items = state.items.filter(item => item.slug !== slug)
  },
  REMOVE_ALL(state) {
    state.items = []
  },
}

export const actions = {
  async FETCH_COMPARES({ commit }) {
    try {
      const response = await this.$api.compare.getCompare()
      const data = response.data.compares
      const compares = data.map((product) => {
        return {
          id: product.id,
          files: product.item.files,
          name: product.name,
          slug: product.item.slug,
        }
      })
      commit('SET_COMPARES', compares)
    } catch (error) {
      // console.log(error)
    }
  },
}
