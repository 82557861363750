const api = {
  baseQuery: () => '/account/orders',
}

export default ($request) => ({
  getOrders(params) {
    return $request.$get(api.baseQuery(), params)
  },
  getOrder(id) {
    return $request.$get(`/account/orders/${id}`)
  },
})
