const api = {
  baseQuery: () => '/compare',
}

export default ($request) => ({
  async getCompare(payload) {
    return await $request.$get(api.baseQuery(), payload)
  },
  async addCompare(payload) {
    return await $request.$post(api.baseQuery(), payload)
  },
  async deleteCompare(id) {
    return await $request.$delete(`/compare/${id}`)
  },
  async deleteCompareAll() {
    return await $request.$delete('/compare/all')
  },
})
