const api = {
  baseQuery: () => '/account/refunds',
}

export default ($request) => ({
  getRefunds(params) {
    return $request.$get(api.baseQuery(), params)
  },
  createRefundFromOrder(id, params) {
    return $request.$post(`account/orders/${id}/refund`, params)
  },
  getRefund(id) {
    return $request.$get(`account/refunds/${id}`)
  },
  editCommentRefund(id, params) {
    return $request.$put(`account/refunds/${id}`, params)
  },
})
