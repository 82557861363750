export default [
  {
    icon: 'company',
    title: 'myCompany',
    to: 'profile-company',
    id: 0,
  },
  {
    icon: 'orders',
    title: 'myOrders',
    to: 'profile-orders',
    id: 1,
  },
  {
    icon: 'heart',
    title: 'favorites',
    to: 'profile-favorites',
    id: 2,
  },
  {
    icon: 'refound',
    title: 'refunds',
    to: 'profile-refunds',
    id: 3,
  },
  {
    icon: 'reviews',
    title: 'tickets',
    to: 'profile-tickets',
    id: 4,
  },
  {
    icon: 'reviews',
    title: 'myReviews',
    to: 'profile-reviews',
    id: 5,
  },
  {
    icon: 'loyalty',
    title: 'loyaltyProgram',
    to: 'profile-loyalty',
    id: 6,
  },
  {
    icon: 'promocodes',
    title: 'promocodes',
    to: 'profile-promocodes',
    id: 7,
  },
]
