import Vue from 'vue'
import Btn from '@/components/GlobalUI/g-btn.vue'
import Icon from '@/components/GlobalUI/g-icon.vue'
import Input from '@/components/GlobalUI/g-input.vue'
import Checkbox from '@/components/GlobalUI/g-checkbox.vue'
import Loader from '@/components/GlobalUI/g-loader.vue'
import Textarea from '@/components/GlobalUI/g-textarea.vue'
import InputPhone from '@/components/GlobalUI/g-inputPhone.vue'

const components = { Btn, Icon, Input, Checkbox, Loader, Textarea, InputPhone }

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})
