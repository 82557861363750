import { FETCH_FAVORITES, SET_FAVORITES, GET_FAVORITES } from './types.favorites'

export const state = () => ({
  items: [],
})

export const getters = {
  [GET_FAVORITES]: (state) => state.items,
}

export const mutations = {
  [SET_FAVORITES]: (state, payload) => (state.items = payload),
}

export const actions = {
  async [FETCH_FAVORITES]({ commit }) {
    try {
      const response = await this.$api.favorites.getFavorites()
      const data = response.data
      if(data) {
        const favorites = data.map((item) => {
          return {
            slug:  item.item.slug,
            name:  item.item.name,
            files: item.item.files,
            entity: item.entity,
          }
        })

        const filterFavorites = favorites.slice(0, 6)
        commit(SET_FAVORITES, filterFavorites)
      }
    } catch (error) {
      // console.log(error)
    }
  },
}
